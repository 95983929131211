var themes = [
   {
      name: 'black',
      bodyColor: 'rgb(20, 20, 20)',
      contrastingBodyColor: 'rgb(30, 30, 30)',
      textColor: 'white',
      positiveButtonColor: '#52fb9e',
      positiveButtonHoverColor: '#36a568',
      othersMessageColor: '#36ff90',
      yourMessageColor: '#45daff',
      hamburgerColor: 'brightness(100%)'
   },
   {
      name: 'gray',
      bodyColor: 'rgb(56, 56, 56)',
      contrastingBodyColor: 'rgb(70,70,70)',
      textColor: 'white',
      positiveButtonColor: '#52fb9e',
      othersMessageColor: '#2de47f',
      positiveButtonHoverColor: '#36a568',
      yourMessageColor: 'rgb(99, 202, 236)',
      hamburgerColor: 'brightness(100%)'
   },
   {
      name: 'light gray',
      bodyColor: '#626262',
      contrastingBodyColor: '#6e6e6e',
      textColor: 'white',
      placeholderColor: '#8d8c8c',
      positiveButtonColor: '#52fb9e',
      othersMessageColor: '#52ff78',
      positiveButtonHoverColor: '#36a568',
      yourMessageColor: '#65d9ff',
      hamburgerColor: 'brightness(100%)'
   },
   {
      name: 'red',
      bodyColor: 'rgb(192, 0, 0)',
      contrastingBodyColor: 'rgb(255, 0, 0)',
      othersMessageColor: '#52ff78',
      yourMessageColor: '#65d9ff',
      placeholderColor: '#e0e0e0',
      textColor: 'white',
      hamburgerColor: 'brightness(100%)',
      positiveButtonHoverColor: '#36a568',
      positiveButtonColor: '#52fb9e',
   },
   {
      name: 'blue',
      bodyColor: 'rgb(0, 66, 192)',
      contrastingBodyColor: 'rgb(0, 88, 255)',
      othersMessageColor: '#ebff00',
      yourMessageColor: '#51ffc6',
      placeholderColor: '#fff',
      textColor: 'white',
      hamburgerColor: 'brightness(100%)',
      positiveButtonHoverColor: '#36a568',
      positiveButtonColor: '#52fb9e',
   },
   {
      name: 'green',
      bodyColor: 'rgb(18, 201, 2)',
      contrastingBodyColor: 'rgb(20, 255, 0)',
      othersMessageColor: '#0004ff',
      yourMessageColor: '#ff2a2a',
      placeholderColor: '#fff',
      textColor: 'black',
      hamburgerColor: 'brightness(0%)',
      positiveButtonColor: '#5f69ff',
      positiveButtonHoverColor: '#36a568',
   }
]
export default themes